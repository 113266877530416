import React from 'react';
import IntroLayout from './IntroLayout';
import { Grid, Typography, TextField, Link, IconButton, Button, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoImg from '../components/LogoImg';
import { IUserCreate } from '../models/create/IUserCreate';
import { isValidEmail, isValidPassword, removeSpaces } from '../utils';
import { apiBaseURL } from '../globals';
import { IUserAuthenticate } from '../models/create/IUserAuthenticate';

type CreateAccountProps = {};

type CreateAccountState = {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string,
    showPassword: boolean,
    emailExists: boolean,
}

/**
 * Create account page
 */
export default class CreateAccount extends React.Component<CreateAccountProps, CreateAccountState> {
    constructor(props: CreateAccountProps) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            showPassword: false,
            emailExists: false,
        };
    }

    /**
     * Submits the user to the backend to be created
     * 
     * @param event submit button click
     */
    handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if ((this.state.password === this.state.confirmPassword) && isValidPassword(this.state.password) && isValidEmail(this.state.email)) {
            const registerUserBody: IUserCreate = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                password: this.state.password,
            }
    
            const response = await fetch(`${apiBaseURL}/users/register`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(registerUserBody)
            });

            if (response.ok) {
                const authenticateUserBody: IUserAuthenticate = {
                    email: this.state.email,
                    password: this.state.password,
                }

                const response = await fetch(`${apiBaseURL}/users/authenticate`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(authenticateUserBody)
                });

                const user = await response.json();

                if (user.token) {
                    localStorage.setItem("token", user.token);
                    window.location.href = "/join/questionnaire";
                }
            } else {
                this.setState({emailExists: true});
            }
        }
    }

    render() {
        return (
            <IntroLayout>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <LogoImg/>
                    </Grid>

                    <Grid item>
                        <Typography variant="h4" align="center">Create Your Account</Typography>
                    </Grid>

                    <form onSubmit={this.handleSubmit} autoComplete="off">
                        <Grid item container spacing={2} direction="column" alignItems="center">
                            <Grid item>
                                <TextField label="First Name" name="firstName" type="text"
                                    value={this.state.firstName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({firstName: event.target.value})}
                                    required
                                />
                            </Grid>

                            <Grid item>
                                <TextField label="Last Name" name="lastName" type="text"
                                    value={this.state.lastName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({lastName: event.target.value})}
                                    required
                                />
                            </Grid>

                            <Grid item>
                                <TextField label="Email" name="email" type="email"
                                    value={this.state.email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({email: event.target.value, emailExists: false})}
                                    required
                                    error={(!isValidEmail(this.state.email) && (this.state.email !== "")) || this.state.emailExists}
                                    helperText={this.state.emailExists ?  "An account already exists for this email address": null}
                                />
                            </Grid>

                            <Grid item>
                                <Tooltip title={!isValidPassword(this.state.password) ? "Passwords must be 8 to 20 characters and contain at least 1 number, 1 uppercase letter, and 1 lowercase letter" : ""} disableHoverListener>
                                    <TextField
                                        label="Password" name="password"
                                        type={this.state.showPassword ? "text" : "password"}
                                        value={this.state.password}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({password: removeSpaces(event.target.value)})}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton size="small" onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                                                    <FontAwesomeIcon icon={this.state.showPassword ? "eye-slash" : "eye"} fixedWidth/>
                                                </IconButton>
                                            ),
                                        }}
                                        required
                                        error={!isValidPassword(this.state.password) && (this.state.password !== "")}
                                    />
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <TextField
                                    label="Confirm Password" name="confirmPassword"
                                    type={this.state.showPassword ? "text" : "password"}
                                    value={this.state.confirmPassword}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({confirmPassword: removeSpaces(event.target.value)})}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                                                <FontAwesomeIcon icon={this.state.showPassword ? "eye-slash" : "eye"} fixedWidth/>
                                            </IconButton>
                                        ),
                                    }}
                                    required
                                    error={this.state.password !== this.state.confirmPassword}
                                    helperText={this.state.password !== this.state.confirmPassword ? "The passwords do not match" : null}
                                />
                            </Grid>

                            <Grid item>
                                <Button type="submit">Create Account</Button>
                            </Grid>
                        </Grid>
                    </form>

                    <Grid item>
                        <Typography>
                            Or <Link href="/">Log In</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </IntroLayout>
        );
    }
}